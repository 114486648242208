

















import { Component, Vue } from "vue-property-decorator";
import TransactionForm from "@/components/TransactionForm.vue";
import TelesurBalance from "@/components/TelesurBalance.vue";
import DigicelBalance from "@/components/DigicelBalance.vue";

@Component({
  components: {
    DigicelBalance,
    TelesurBalance,
    TransactionForm
  }
})
export default class AddTransaction extends Vue {}
