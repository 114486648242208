









import { Component, Vue } from "vue-property-decorator";
@Component
export default class DigicelBalance extends Vue {
  get balance() {
    return "D " + (this.$modules.topup.balanceDigicel / 100);
  }

  calculateBalance() {
    alert("Need to get the balance");
  }
}
