





















import { Component, Vue } from "vue-property-decorator";
import SignOut from "@/components/SignOut.vue";
@Component({
  components: {
    SignOut
  }
})
export default class ClientHeader extends Vue {

}
