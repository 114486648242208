









































import { Component, Vue } from "vue-property-decorator";
import EndOfDay from "@/components/EndOfDay.vue";
import CheckBalance from "@/components/CheckBalance.vue";
import TelesurBalance from "@/components/TelesurBalance.vue";
import DigicelBalance from "@/components/DigicelBalance.vue";
import DigicelEndOfDay from "@/components/DigicelEndOfDay.vue";
import TelesurEndOfDay from "@/components/TelesurEndOfDay.vue";
@Component({
  components: {TelesurEndOfDay, DigicelEndOfDay, DigicelBalance, TelesurBalance, CheckBalance, EndOfDay }
})
export default class AdministrationShow extends Vue {
  buyTelesur(){
    this.$modules.account.buyTelesur(10000);
    this.$modules.topup.getBalance("Telesur");
    //todo subtract
  }
  buyDigicel(){
    this.$modules.account.buyDigicel(10000);
    this.$modules.topup.getBalance("Digicel");
    //todo subtract
  }
}
