







import { Component, Vue } from "vue-property-decorator";
@Component
export default class Administration extends Vue {
  get balance() {
    return (this.$modules.account.balance / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "SRD",
      minimumFractionDigits: 2,
    });
  }
  calculateBalance() {
    alert("Need to get the balance");
  }
}
