

















import { Component, Vue } from "vue-property-decorator";
import ClientHeader from "@/components/ClientHeader.vue";
import AdminAddClient from "@/components/AdminAddClient.vue";

@Component({
  components: {
    AdminAddClient,
    ClientHeader
  }
})
export default class Admin extends Vue {
}
