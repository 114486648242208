var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[(_vm.searchEnabled)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-spacer'),_c('list-of-transactions-refresh')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.transactions,"item-class":"provider","item-key":"name","loading":_vm.transactionsLoading,"loading-text":"Tan Tirie. Help je zelf","options":_vm.options,"server-items-length":_vm.totalTransactions,"search":_vm.search,"footer-props":{
      itemsPerPageOptions: [5,10,15],
      itemsPerPageText: 'Rows per page',
      'show-current-page': true,
      'show-first-last-page': true
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.formattedAmount",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.provider)}},[_vm._v(" "+_vm._s(item.formattedAmount)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }