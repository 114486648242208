









































import { Component, Vue, Watch } from "vue-property-decorator";
import ListOfTransactionsRefresh from "@/components/ListOfTransactionsRefresh.vue";
import {PaginateOptions} from "@/models/paginate_options";
@Component({
  components: {ListOfTransactionsRefresh}
})
export default class TransactionStatus extends Vue {
  headers = [
    {
      text: "Tijd",
      align: "start",
      sortable: false,
      groupable: false,
      value: "datetimeLocal"

    },
    {
      text: "Tel #",
      align: "start",
      sortable: false,
      value: "formattedPhoneNumber"
    },
    {
      text: "Bedrag",
      align: "start",
      sortable: false,
      value: "formattedAmount"
    },
    {
      text: "Status",
      align: "start",
      sortable: false,
      value: "status"
    },
    {
      text: "Reference #",
      align: "start",
      sortable: false,
      groupable: false,
      value: "referenceNumber"
    },
    {
      text: "Provider",
      align: "start",
      sortable: false,
      value: "provider"
    }
  ];

  search = "";
  options = new PaginateOptions(
      [],
      [],
      5,
      false,
      false,
      [],
      1,
      []
);

  get totalTransactions() {
    return this.$modules.topup.totalTransactions;
  }
  get transactions() {
    return this.$modules.topup.transactions;
  }

  get transactionsLoading() {
    return this.$modules.topup.transactionsLoading
  }

  mounted(){
    this.$modules.topup.getTransactions(this.options);
  }
  getColor(provider: string) {
    if(provider === 'Telesur'){
      return "secondary";
    }else if(provider === 'Digicel'){
      return "red";
    }
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.$modules.topup.getTransactions(this.options);
  }
  @Watch('search')
  onSearchChanged() {
    this.$modules.topup.findTransactions(this.search);
  }
  searchEnabled = false
}
