










import { Component, Vue } from "vue-property-decorator";
import ListOfTransactions from "@/components/ListOfTransactions.vue";
import ListOfTransactionsRefresh from "@/components/ListOfTransactionsRefresh.vue";
@Component({
  components: {
    ListOfTransactionsRefresh,
    ListOfTransactions
  }
})
export default class TransactionStatus extends Vue {}
