























































import { Component, Vue } from "vue-property-decorator";
import account from "@/store/modules/account";
@Component
export default class AdminAddClient extends Vue {
  dialog = false
  client = {
    companyName: '',
    tenant: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: ''
    }
  }
  save() {
    this.dialog = false
    this.$modules.client.addClient(this.client.companyName);
    this.$modules.client.addTenant(this.client.companyName, this.client.tenant);
  }
}
