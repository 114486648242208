






import { Emit, Component, Vue } from "vue-property-decorator";
import {PaginateOptions} from "@/models/paginate_options";
@Component({
  computed: {
    loading() {
      return this.$modules.topup.transactionsLoading;
    }
  }
})
export default class ListOfTransactionsRefresh extends Vue {
  options = new PaginateOptions(
      [],
      [],
      10,
      false,
      false,
      [],
      1,
      []
  );
  @Emit()
  getTransactions() {
    this.$modules.topup.getTransactions(this.options);
  }
}
