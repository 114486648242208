









import { Component, Vue } from "vue-property-decorator";
@Component
export default class TelesurBalance extends Vue {
  get balance() {
    return "T " + this.$modules.topup.balanceTelesur / 100;
  }
  calculateBalance() {
    alert("Need to get the balance");
  }
}
