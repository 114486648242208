




import { Component, Vue } from "vue-property-decorator";
@Component
export default class Administration extends Vue {
  calculateEndOfDay() {
    alert("Need to calculate the sum of all transactions");
  }
}
