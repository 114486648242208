




































import { Component, Vue } from "vue-property-decorator";
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;
@Component({
  components: {}
})
export default class DigicelEndOfDay extends Vue {
  dialogEODTelesur = false

  get eodTelesur() {
    // this.$modules.topup.getTransactions();
    const options: DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "America/Paramaribo",
    };
    const formatter = new Intl.DateTimeFormat("nl-SR", options);
    const formattedDate = formatter.format(Date.now());
    // return formatter.format(new Date(this.isoDateOfRequest));
    const total = this.$modules.topup.transactions.reduce(
      function(acc, val) {
        const transactionFormattedDate = formatter.format(new Date(val.isoDateOfRequest));
        if(val.isTelesur && transactionFormattedDate === formattedDate)
          return acc + val.amountInCents;
        else
          return acc;
      }, 0
    );
    return (total / 100).toLocaleString(
      "en-US",
      {
        style: "currency",
        currency: "SRD",
        minimumFractionDigits: 2
      });
  }
}
