

















import { Component, Vue } from "vue-property-decorator";
import TransactionsIndex from "@/views/Client/TransactionsIndex.vue";
import AdministrationShow from "@/views/Client/AdministrationShow.vue";
import TransactionAdd from "@/views/Client/TransactionAdd.vue";
import ClientHeader from "@/components/ClientHeader.vue";

@Component({
  components: {
    ClientHeader,
    TransactionAdd,
    AdministrationShow,
    TransactionsIndex
  }
})
export default class Client extends Vue {
}
